<template>
  <div class="videoBox">
    <div v-show="isLive" :class="isShowBgColor ? 'jessibuca' : 'jessibucaOP'" ref="jessibuca"></div>
    <video :style="{'visibility':!isLive? 'visible':'hidden'}" :class="isShowBgColor ? 'jessibuca' : 'jessibucaOP'" :src="url" v-bind="videoConfig" v-on="videoEvent"> </video>
  </div>
</template>
<script>
export default {
  name: "JessibucaPro",
  props: {
    //连接地址
    url: {
      type: String,
      default: "",
    },
    extendData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    //缓存时长
    videoBuffer: {
      type: Number,
      default: 0.2,
    },
    videoBufferDelay: {
      type: Number,
      default: 1,
    },
    //worker地址
    decoder: {
      type: String,
      default: "/static/pro/decoder-pro.js",
    },
    //worker地址
    decoderAudio: {
      type: String,
      default: "/static/pro/decoder-pro-audio.js",
    },
    //是否不使用离屏模式（提升渲染能力）
    forceNoOffscreen: {
      type: Boolean,
      default: true,
    },
    //是否开启当页面的'visibilityState'变为'hidden'的时候，自动暂停播放
    hiddenAutoPause: {
      type: Boolean,
      default: false,
    },
    //是否有音频，如果设置false，则不对音频数据解码，提升性能
    hasAudio: {
      type: Boolean,
      default: true,
    },
    //解码视频
    hasVideo: {
      type: Boolean,
      default: true,
    },
    //设置旋转角度，只支持，0(默认) ，180，270 三个值
    rotate: {
      type: Number,
      default: 0,
    },
    //视频画面做等比缩放
    isResize: {
      type: Boolean,
      default: false,
    },
    //画面是否拉伸
    isFullResize: {
      type: Boolean,
      default: false,
    },
    //当为true的时候：ws协议不检验是否以.flv为依据，进行协议解析
    isFlv: {
      type: Boolean,
      default: true,
    },
    //设置Fmp4格式
    isFmp4: {
      type: Boolean,
      default: false,
    },
    // 是否开启控制台调试打印
    debug: {
      type: Boolean,
      default: false,
    },
    // 日志等级
    debugLevel: {
      type: String,
      default: 'debug',//warn\debug
    },
    //设置超时时长
    timeout: {
      type: Number,
      default: 10,
    },
    //设置心跳超时时长
    heartTimeout: {
      type: Number,
      default: 5,
    },
    //是否开启心跳超时之后自动再播放
    heartTimeoutReplay: {
      type: Boolean,
      default: true,
    },
    //心跳超时之后重试次数
    heartTimeoutReplayTimes: {
      type: Number,
      default: -1,
    },
    //loading超时时长
    loadingTimeout: {
      type: Number,
      default: 10,
    },
    //是否开启loading超时之后自动再播放
    loadingTimeoutReplay: {
      type: Boolean,
      default: true,
    },
    //loading自动再播放重试次数
    loadingTimeoutReplayTimes: {
      type: Number,
      default: -1,
    },
    //是否支持屏幕的双击事件，触发全屏，取消全屏事件
    supportDblclickFullscreen: {
      type: Boolean,
      default: true,
    },
    // 显示网速
    showBandwidth: {
      type: Boolean,
      default: false,
    },
    // 显示性能
    showPerformance: {
      type: Boolean,
      default: false,
    },
    //是否屏幕常亮
    keepScreenOn: {
      type: Boolean,
      default: false,
    },
    //是否开启声音
    isNotMute: {
      type: Boolean,
      default: false,
    },
    //加载过程中文案
    loadingText: {
      type: String,
      default: "正在加载视频流",
    },
    //加载过程中背景
    loadingBackground: {
      type: String,
      default: "",
    },
    //背景图片
    background: {
      type: String,
      default: "",
    },
    //是否开启MediaSource硬解码 w7可以播MP4 w10可以播MP4
    useMSE: {
      type: Boolean,
      default: false,
    },
    //使用SIMD
    useSIMD: {
      type: Boolean,
      default: true,
    },
    //遇到相同时间戳是否丢gop
    isDropSameTimestampGop: {
      type: Boolean,
      default: false,
    },
    //硬解码(MediaSource，Webcodec)worker解封装
    demuxUseWorker: {
      type: Boolean,
      default: true,
    },
    //硬解码(MediaSource)worker解码
    mseDecoderUseWorker: {
      type: Boolean,
      default: false,
    },
    //使用多线程解码
    useMT: {
      type: Boolean,
      default: true,
    },
    //检查首帧是否I帧
    checkFirstIFrame: {
      type: Boolean,
      default: true,
    },
    //是否开启SIMD硬解码
    SIMD: {
      type: Boolean,
      default: false,
    },
    //是否开启Webcodecs硬解码 w7可以播MP4
    useWCS: {
      type: Boolean,
      default: false,
    },
    //音视频同步
    syncAudioAndVideo: {
      type: Boolean,
      default: true,
    },
    //硬解码是否通过video标签渲染
    useVideoRender: {
      type: Boolean,
      default: true,
    },
    //webcodecs硬解码是否通过video标签渲染
    wcsUseVideoRender: {
      type: Boolean,
      default: false,
    },
    //在使用MSE或者Webcodecs 播放H265的时候，是否自动降级到wasm模式
    autoWasm: {
      type: Boolean,
      default: true,
    },
    //是否开启键盘快捷键
    hotKey: {
      type: Boolean,
      default: true,
    },
    //是否开启解码失败重新播放
    wasmDecodeErrorReplay: {
      type: Boolean,
      default: true,
    },
    //底部控制台是否自动隐藏
    controlAutoHide: {
      type: Boolean,
      default: true,
    },
    //底部控制台追加 html
    controlHtml: {
      type: String,
      default: "",
    },
    //默认录制的视频格式
    recordType: {
      type: String,
      default: "webm",//可以mp4,但mp4没声音
    },
    //是否使用web全屏(旋转90度)（只会在移动端生效）
    useWebFullScreen: {
      type: Boolean,
      default: false,
    },
    //超时使用最后一帧显示
    heartTimeoutReplayUseLastFrameShow: {
      type: Boolean,
      default: true,
    },
    //重播使用最后一帧显示
    replayUseLastFrameShow: {
      type: Boolean,
      default: true,
    },
    //是否显示加载图标
    loadingIcon: {
      type: Boolean,
      default: true,
    },
    //清晰度
    qualityConfig: {
      type: Array,
      default() {
        return ["普清", "高清", "超清", "4K", "8K"];
      },
    },
    //是否显示背景色
    isShowBgColor: {
      type: Boolean,
      default: true,
    },
    //操作按钮
    operateBtns: {
      type: Object,
      default() {
        return {
          fullscreen: true, //是否显示全屏按钮
          screenshot: true, //是否显示截图按钮
          play: false, //是否显示播放暂停按钮
          audio: true, //是否显示声音按钮
          record: true, //是否显示录制按钮
          ptz: false, //是否显示操作盘,
          quality: false, //是否显示质量,
          performance: false, //是否显示性能面板,
          zoom: false, //是否显示电子放大,
          close: true, //是否显示关闭,
          scale: true, //是否显示比例,
          aiFace: false, //ai人脸
        };
      },
    },
    //水印配置
    watermarkConfig: {
      type: Object,
      default() {
        return {
          text: {
            content: "aebell",
          },
          right: 10,
          top: 10,
        };
      },
    },
    //video配置
    videoConfig: {
      type: Object,
      default() {
        return {
          controls: true,
          loop: true,
          autoplay: true,
          muted: false,
        };
      },
    },
    //video事件
    videoEvent: {
      type: [Object],
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      //是否直播流
      isLive: true,
    };
  },
  watch: {
    url: {
      handler(newVal) {
        if (newVal != "" && newVal != null) {
          if (this.$options.jessibuca) {
            this.destroy().then(() => {
              this.$options.jessibuca = null;
              this.init();
            });
          } else {
            this.init();
          }
        } else {
          if (this.$options.jessibuca) {
            this.destroy().then(() => {
              this.$options.jessibuca = null;
            });
          }
        }
      },
      deep: true,
      immediate: false,
    },
  },
  mounted() {
    if (this.url != "" && this.url != null) {
      this.init();
    }
  },
  methods: {
    //初始化视频
    init() {
      this.isLive = this.judgmentFile();
      if (this.isLive) {
        //创建播放器
        this.$options.jessibuca = new JessibucaPro(Object.assign({
          container: this.$refs.jessibuca,
          videoBuffer: this.videoBuffer,
          videoBufferDelay: this.videoBufferDelay,
          decoder: this.decoder,
          decoderAudio: this.decoderAudio,
          forceNoOffscreen: this.forceNoOffscreen,
          hiddenAutoPause: this.hiddenAutoPause,
          hasAudio: this.hasAudio,
          hasVideo: this.hasVideo,
          rotate: this.rotate,
          isResize: this.isResize,
          isFullResize: this.isFullResize,
          isFlv: this.isFlv,
          isFmp4: this.isFmp4,
          debug: this.debug,
          debugLevel: this.debugLevel,
          timeout: this.timeout,
          heartTimeout: this.heartTimeout,
          heartTimeoutReplay: this.heartTimeoutReplay,
          heartTimeoutReplayTimes: this.heartTimeoutReplayTimes,
          heartTimeoutReplayUseLastFrameShow: this.heartTimeoutReplayUseLastFrameShow,
          replayUseLastFrameShow: this.replayUseLastFrameShow,
          loadingTimeout: this.loadingTimeout,
          loadingTimeoutReplay: this.loadingTimeoutReplay,
          loadingTimeoutReplayTimes: this.loadingTimeoutReplayTimes,
          supportDblclickFullscreen: this.supportDblclickFullscreen,
          showBandwidth: this.showBandwidth,
          showPerformance: this.showPerformance,
          keepScreenOn: this.keepScreenOn,
          isNotMute: this.isNotMute,
          loadingText: this.loadingText,
          loadingBackground: this.loadingBackground,
          loadingIcon: this.loadingIcon,
          background: this.background,
          useMSE: this.useMSE,
          useSIMD: this.useSIMD,
          SIMD: this.SIMD,
          networkDelayTimeoutReplay:false,
          playbackForwardMaxRateDecodeIFrame: 8,
          checkFirstIFrame:this.checkFirstIFrame,
          useMThreading: this.useMT,
          useWCS: this.useWCS,
          syncAudioAndVideo: this.syncAudioAndVideo,
          useVideoRender: this.useVideoRender,
          wcsUseVideoRender: this.wcsUseVideoRender,
          autoWasm: this.autoWasm,
          hotKey: this.hotKey,
          wasmDecodeErrorReplay: this.wasmDecodeErrorReplay,
          controlAutoHide: this.controlAutoHide,
          controlHtml: this.controlHtml,
          recordType: this.recordType,
          useWebFullScreen: this.useWebFullScreen,
          qualityConfig: this.qualityConfig,
          operateBtns: this.operateBtns,
          watermarkConfig: this.watermarkConfig,
          ptzZoomShow: false,
          ptzMoreArrow: false,
          ptzApertureShow: false,
          ptzFocusShow: false,
          isDropSameTimestampGop: this.isDropSameTimestampGop,
          demuxUseWorker: this.demuxUseWorker,
          mseDecoderUseWorker: this.mseDecoderUseWorker,
          supportHls265:true,//hls是否是H265编码
          // scaleMode:'1',
        }));
        // this.$options.jessibuca.setScaleMode('1');//设置默认正常比例

        //播放视频
        this.play(this.url);

        //初始化事件
        this.$options.jessibuca.on("load", (e) => {
          this.$emit("load", e);
        });
        //当前视频帧pts
        this.$options.jessibuca.on("timeUpdate", (e) => {
          this.$emit("timeUpdate", e);
        });
        //当解析出视频信息时回调
        this.$options.jessibuca.on("videoInfo", (e) => {
          this.$emit("videoInfo", e);
        });
        //当解析出音频信息时回调
        this.$options.jessibuca.on("audioInfo", (e) => {
          this.$emit("audioInfo", e);
        });
        //信息，包含错误信息
        this.$options.jessibuca.on("log", (e) => {
          console.log('log',e);
          this.$emit("log", e);
        });
        //错误信息
        this.$options.jessibuca.on("error", (e) => {
          console.log('报错',e);
          this.$emit("error", e);
          // 这里统一的做重连。
          if (
            e === "websocketError" ||
            e === "webrtcError" ||
            e === "hlsError"
          ) {
            if (this.$options.jessibuca) {
              // this.$options.jessibuca.destroy().then(() => {
              //   this.init();
              // });
              this.$emit('reload',this.url)
            } else {
              this.init();
            }
          }
        });
        //当前网速
        this.$options.jessibuca.on("kBps", (e) => {
          this.$emit("kBps", e);
        });
        //渲染开始
        this.$options.jessibuca.on("start", (e) => {
          this.$emit("start", e);
        });
        //当设定的超时时间内无数据返回,则回调
        this.$options.jessibuca.on("timeout", (e) => {
          let extendData = {
            e: e,
            extendData: this.extendData,
          };
          this.$emit("timeout", extendData);
          // this.$emit("timeout", e, this.extendData);
        });
        //当play()的时候，如果没有数据返回，则回调,则回调
        this.$options.jessibuca.on("loadingTimeout", (e) => {
          this.$emit("loadingTimeout", e);
        });
        //当播放过程中，如果超过timeout之后没有数据渲染，则抛出异常
        this.$options.jessibuca.on("delayTimeout", (e) => {
          this.$emit("delayTimeout", e);
        });
        //当前是否全屏
        this.$options.jessibuca.on("fullscreen", (e) => {
          this.$emit("fullscreen", e);
        });
        //当前是否web全屏
        this.$options.jessibuca.on("webFullscreen", (e) => {
          this.$emit("webFullscreen", e);
        });
        //触发播放事件
        this.$options.jessibuca.on("play", (e) => {
          this.$emit("play", e);
          this.$options.jessibuca.setScaleMode('1');//设置默认正常比例
        });
        //触发暂停事件
        this.$options.jessibuca.on("pause", (e) => {
          this.$emit("pause", e);
        });
        //触发声音事件
        this.$options.jessibuca.on("mute", (e) => {
          this.$emit("mute", e);
        });
        //流状态统计
        this.$options.jessibuca.on("stats", (e) => {
          this.$emit("stats", e);
        });
        //渲染性能统计
        this.$options.jessibuca.on("performance", (e) => {
          this.$emit("performance", e);
        });
        //录制开始的事件
        this.$options.jessibuca.on("recordStart", (e) => {
          this.$emit("recordStart", e);
        });
        //录制结束的事件
        this.$options.jessibuca.on("recordEnd", (e) => {
          this.$emit("recordEnd", e);
        });
        //录制的时候，返回的录制时长
        this.$options.jessibuca.on("recordingTimestamp", (e) => {
          this.$emit("recordingTimestamp", e);
        });
        //监听调用play方法
        this.$options.jessibuca.on("playToRenderTimes", (e) => {
          this.$emit("playToRenderTimes", e);
        });
        // 监听云台点击
        this.$options.jessibuca.on("ptz", (arrow) => {
          this.$emit("ptz", arrow);
        });
        // 监听关闭
        this.$options.jessibuca.on("close", (e) => {
          this.$emit("close", e);
        });
      }
    },
    //是否开启控制台调试打印
    setDebug(boolean) {
      return this.$options.jessibuca.setDebug(boolean);
    },
    //静音
    mute() {
      return this.$options.jessibuca.mute();
    },
    //取消静音
    cancelMute() {
      return this.$options.jessibuca.cancelMute();
    },
    //设置超时时长
    setTimeout(time) {
      return this.$options.jessibuca.setTimeout(time);
    },
    //暂停视频
    pause() {
      return this.$options.jessibuca.pause();
    },
    //关闭视频,不释放底层资源
    close() {
      return this.$options.jessibuca?.close();
    },
    //关闭视频，释放底层资源
    destroy() {
      return this.$options.jessibuca?.destroy();
    },
    //清除播放缓存
    playbackClearCacheBuffer() {
      return this.$options.jessibuca?.playbackClearCacheBuffer();
    },
    //清理画布为黑色背景
    clearView() {
      return this.$options.jessibuca.clearView();
    },
    //播放视频
    play(url) {
      return this.$options.jessibuca?.play(url);
    },
    //重新调整视图大小
    resize() {
      return this.$options.jessibuca.resize();
    },
    //设置最大缓冲时长
    setBufferTime(time) {
      return this.$options.jessibuca.setBufferTime(time);
    },
    //设置旋转角度
    setRotate(deg) {
      return this.$options.jessibuca.setRotate(deg);
    },
    //设置音量大小
    setVolume(volume) {
      return this.$options.jessibuca.setVolume(volume);
    },
    //返回是否加载完毕
    hasLoaded() {
      return this.$options.jessibuca.hasLoaded();
    },
    //开启屏幕常亮
    setKeepScreenOn() {
      return this.$options.jessibuca.setKeepScreenOn();
    },
    //全屏(取消全屏)播放视频
    setFullscreen(flag) {
      return this.$options.jessibuca.setFullscreen(flag);
    },
    //截图
    screenshot(filename, format, quality, type) {
      return this.$options.jessibuca.screenshot(
        filename,
        format,
        quality,
        type
      );
    },
    //开始录制
    screenshot(filename, fileType) {
      return this.$options.jessibuca.screenshot(filename, fileType);
    },
    //暂停录制并下载
    stopRecordAndSave() {
      return this.$options.jessibuca.stopRecordAndSave();
    },
    //返回是否正在播放中状态
    isPlaying() {
      return this.$options.jessibuca.isPlaying();
    },
    //返回是否静音
    isMute() {
      return this.$options.jessibuca.isMute();
    },
    //返回是否正在录制
    isRecording() {
      return this.$options.jessibuca.isRecording();
    },
    //判断视频文件的方法
    judgmentFile() {
      const videoFormatRegex = /\.(mp4|webm|ogg)$/i;
      if (videoFormatRegex.test(this.url)) {
        return false;
      } else {
        return true;
      }
    },
  },
  beforeDestroy() {
    if (this.$options.jessibuca) {
      this.destroy().then(() => {
        this.$options.jessibuca = null;
      });
    }
  },
};
</script>

<style lang="scss">
.videoBox {
  width: 100%;
  height: 100%;
}
.jessibuca {
  width: 100%;
  height: 100%;
  .jessibuca-loading{
    .jessibuca-loading-text{
      color: #333;
    }
  }
}
.jessibucaOP {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0) !important;
}
</style>
