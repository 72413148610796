<template>
  <div class='rtPreview'>
    <div class="leftCont mr10" v-if="isShowMap">
      <div class="title_nameR">
        <div class="name">设备列表</div>
        <!-- <el-select v-model="homeId" placeholder="场所" class="csSelect w100" size="mini">
          <el-option v-for="(item,index) in homeList" :key="index" :label="item.homeName" :value="item.id"></el-option>
        </el-select> -->
      </div>
      <device-comp
        :isShowBtnDiv="false" 
        :draggable="true" 
        :nodeDragStart="nodeDragStart"
        :nodeDragEnd="nodeDragEnd"
        :listHeight="'calc(100vh - 361px)'"
        :isAllowDBL="true"
        @treeDblClick="treeDblClick"
        @treeDblClickDBL="treeDblClickDBL"
        @treeClick="treeClick"></device-comp>

      <!--云台-->
      <yun-tai ref="yuntaiRt" :urlList="urlList" :isActive="isActive" :isShowPTZ="isShowPTZ"></yun-tai>
    </div>
    <div class="rightCont">
      <!-- <div class="title_nameR">
        <div>监控列表</div>
      </div> -->
      <monitor ref="monitorRef" :height="monitorH" @activeEmit="activeEmit" @urlListEmit="urlListEmit"></monitor>
    </div>
  </div>
</template>
<script>
import deviceComp from './deviceComp'
import yunTai from '@/views/components/yuntai.vue'
import monitor from '@/views/components/monitor.vue'
import {apiUrl} from '@/assets/js/api'
import qs from 'qs'
var vm;
export default {
  props:['isShowMap'],
  data() {
    return {
      homeList:[],//场所列表
      
      // 是否展示 云台
      isPtz:true,
      
      urlList:[],
      isActive:-1,
      isShowPTZ:false,
      
      monitorH:'calc(100vh - 155px)'
    }
  },
  components:{
    deviceComp,
    yunTai,
    monitor,
  },
  watch:{
    // '$store.state.csType':function(newVal){
      
    // }
  },
  created(){
    vm = this;
  },
  mounted(){
    // getPlayUrl()
  },
  activated(){
    vm = this
  },

  //方法
  methods:{
    //组件点击节点返回
    treeClick (val){
      vm.$emit('treeClick',val)
    },
    //tree组件双击组返回
    async treeDblClickDBL(data){
      //先把所有都停掉
      let urlList = this.$refs.monitorRef.urlList
      if(urlList.length>0){
        const haswebrtcUrl = urlList.some(item => item.webrtcUrl);
        //判断是否有视频地址（是否有视频在播放）
        if(haswebrtcUrl){
          for(var i in urlList){
            if(urlList[i].webrtcUrl)
            await this.$refs.monitorRef.closeItemVideo(i)
          }
          this.$refs.monitorRef.stopRound()
          this.$refs.monitorRef.curr = 1
        }
      }
      this.treeDblItem(data)
    },
    async treeDblItem(data){
      let arr = vm.$refs.monitorRef.urlList = []
      let newData = JSON.parse(JSON.stringify(data))
      // let newData = data.filter(item => item.equStatus==1)
      // if(newData.length<data.length){
      //   vm.$message.error('当前设备不在线')
      // }
      let offArr = []
      for(var i =0;i<newData.length;i++){
        if(newData[i].equStatus=='0'){
          // vm.$message.error('当前设备不在线')
          // return false
          // continue
          offArr.push(newData[i].name)
          newData.splice(i,1)
          i--
        }else{
          let webrtcUrl = await vm.deviceMediaLive(newData[i].id,i)
          if(webrtcUrl){
            let obj = {
              'id':newData[i].id,
              'webrtcUrl':webrtcUrl,
              'isPtz': newData[i].isPtz,//是否有云台
              'is_Intercom': newData[i].is_Intercom,//是否有对讲
              'equStatus': newData[i].equStatus,//是否在线
            }
            arr.push(obj)
            vm.$refs.monitorRef.initCurr(i)
          }
        }
      }
      if(offArr.length>0){
        vm.$message.error(`${offArr.join('、')}通道已离线`)
      }
    },
    //tree组件双击设备返回
    async treeDblClick(val){
      if(val.equStatus==0){
        vm.$message.error('当前设备不在线')
        return false
      }
      let urlList = vm.$refs.monitorRef.urlList
      let isHasArr = urlList.filter(obj => obj.id === val.id);
      if(isHasArr.length > 0){
        vm.$message.warning('当前设备已在查看列表中')
        return false
      }
      // if(vm.$refs.monitorRef.urlList.length==0){
        let arr = []
        let dataArr = vm.$refs.monitorRef.gridinfoList.arr.length>vm.$refs.monitorRef.urlList.length?vm.$refs.monitorRef.gridinfoList.arr.length:vm.$refs.monitorRef.urlList.length
        for(var index=0;index<dataArr;index++){
          let obj = {
            'id':vm.$refs.monitorRef.urlList[index]?.id ? vm.$refs.monitorRef.urlList[index].id : '',
            'webrtcUrl':vm.$refs.monitorRef.urlList[index]?.webrtcUrl ? vm.$refs.monitorRef.urlList[index].webrtcUrl : '',
            'isPtz':vm.$refs.monitorRef.urlList[index]?.isPtz ? vm.$refs.monitorRef.urlList[index].isPtz : 0,//是否有云台
            'is_Intercom':vm.$refs.monitorRef.urlList[index]?.is_Intercom ? vm.$refs.monitorRef.urlList[index].is_Intercom : 0,//是否有对讲
            'equStatus': vm.$refs.monitorRef.urlList[index]?.equStatus ? vm.$refs.monitorRef.urlList[index].equStatus : 0,//是否在线
          }
          arr.push(obj)
        }
        vm.$refs.monitorRef.urlList = arr
      // }

      for(var i in vm.$refs.monitorRef.urlList){
        if(vm.$refs.monitorRef.urlList[i].id == ''){
          let webrtcUrl = await vm.deviceMediaLive(val.id,i)
          if(webrtcUrl){
            vm.$refs.monitorRef.urlList[i].id = val.id
            vm.$refs.monitorRef.urlList[i].webrtcUrl = webrtcUrl
            vm.$refs.monitorRef.urlList[i].isPtz = val.isPtz
            vm.$refs.monitorRef.urlList[i].is_Intercom = val.is_Intercom
            vm.$refs.monitorRef.urlList[i].equStatus = val.equStatus
            vm.$refs.monitorRef.initCurr(i)
            return
          }else{
            return
          }
        }
      }
    },
    //视频墙组件返回
    activeEmit(val){
      vm.isActive = val
      vm.isShowPTZ = vm.urlList[vm.isActive]?.isPtz==1 ? true :false
    },
    urlListEmit(val){
      vm.urlList = val
      vm.isShowPTZ = vm.urlList[vm.isActive]?.isPtz==1 ? true :false
    },
    
    //设置tree节点class
    customNodeClass (data, node) {
      if (data.onLine==false) {
        return 'isDisabled'
      }
      return null
    },
    //筛选过滤树
    filterNode (value, data) {
      // console.log('filterNode',value,data);
      if (!value) return true
      return data.name.includes(value)
    },
    // 判断是否能被拖拽
    allowDrag(draggingNode){
      return draggingNode.childNodes.length == 0;
    },
    // 拖拽时判定目标节点能否被放置
    allowDrop(draggingNode, dropNode, type){
      return false;
    },
    // 节点被点击事件
    nodeClick (data) {
      if (data.children.length <= 0) return;
      vm.selectNode = data.children;
    },
    // 节点开始拖拽时触发的事件
    nodeDragStart (Node, event){
      // console.log('开始拖拽',Node);
      if (Node.data?.isPtz==1) {
        vm.isPtz = true;
      } else {
        vm.isPtz = false;
      }
    },
    // 拖拽结束时（可能未成功）触发的事件
    async nodeDragEnd (e,node){
      // console.log('拖拽结束1',node);
      //判断拉取的是否是设备
      if(node.data.nodeType!='channel'){
        return false
      }
      //判断是否离线
      if(node.data.equStatus==0){
        vm.$message.error('当前设备不在线')
        return false
      }
      let urlList = vm.$refs.monitorRef.urlList
      let isHasArr = urlList.filter(obj => obj.id === node.data.id);
      if(isHasArr.length > 0){
        vm.$message.warning('当前设备已在查看列表中')
        return false
      }
      
      //放置
      let x = 0;
      let y = 0;
      if (e.originalEvent.type == "touchend") {
        x = e.originalEvent.changedTouches[0].clientX;
        y = e.originalEvent.changedTouches[0].clientY;
      } else {
        x = e.originalEvent.clientX;
        y = e.originalEvent.clientY;
      }
      let i = vm.isCoordinate(x, y);
      // console.log('拖拽结束2:',i);
      if (i >= 0) {
        
        if(this.$refs.monitorRef.loadData[i].isLoading){
          return vm.$message.warning('请稍后或更改其他视频框')
        }
        // ptz 是否为云台； cameraId 为摄像机ID
        // let { ptz, cameraId } = node.data;
        // let query = {
        //   cameraId,
        //   stream: 0,
        // };
        let webrtcUrl = await vm.deviceMediaLive(node.data.id,i);//获取流地址
        
        if (vm.$refs.monitorRef.urlList[i]?.id) {
          await vm.$refs.monitorRef.closeItemVideo(i);
        }
        // console.log('webrtcUrl:',webrtcUrl);
        if(vm.$refs.monitorRef.gridinfoList.arr[i] && webrtcUrl) {
          vm.$refs.monitorRef.gridinfoList.arr[i].webrtcUrl = webrtcUrl
          // vm.$refs.monitorRef.gridinfoList.arr[i].isClose = true
        }
        // let urlArr = JSON.parse(JSON.stringify(vm.$refs.monitorRef.urlList))
        let arr = []
        let dataArr = vm.$refs.monitorRef.gridinfoList.arr.length>vm.$refs.monitorRef.urlList.length?vm.$refs.monitorRef.gridinfoList.arr.length:vm.$refs.monitorRef.urlList.length
        for(var index=0;index<dataArr;index++){
          let obj = {
            'id':vm.$refs.monitorRef.urlList[index]?.id?vm.$refs.monitorRef.urlList[index].id : i==index&&webrtcUrl?node.data.id:'',
            'webrtcUrl':vm.$refs.monitorRef.urlList[index]?.webrtcUrl?vm.$refs.monitorRef.urlList[index].webrtcUrl : i==index&&webrtcUrl?webrtcUrl:'',
            'isPtz':vm.$refs.monitorRef.urlList[index]?.isPtz?vm.$refs.monitorRef.urlList[index].isPtz : i==index&&webrtcUrl?node.data.isPtz:0,//是否有云台
            'is_Intercom':vm.$refs.monitorRef.urlList[index]?.is_Intercom?vm.$refs.monitorRef.urlList[index].is_Intercom : i==index&&webrtcUrl?node.data.is_Intercom:0,//是否有对讲
            'equStatus':vm.$refs.monitorRef.urlList[index]?.equStatus?vm.$refs.monitorRef.urlList[index].equStatus : i==index&&webrtcUrl?node.data.equStatus:0,//是否在线
          }
          arr.push(obj)
        }
        vm.$refs.monitorRef.urlList = arr
        vm.urlList = arr
        // gridinfoList.arr[i].ptz=ptz
        // gridinfoList.arr[i].cameraId=cameraId
        // 选中该视频组件
        // console.log(vm.$refs.monitorRef.isActive);
        vm.$refs.monitorRef.isActive = i;
        vm.isActive = i
        // if(webrtcUrl) vm.$refs.monitorRef.createVideo();
        
      }
    },
    // 判断拖拽结束点是否在该区域
    isCoordinate (x, y) {
      for (let index = 0; index < vm.$refs.monitorRef.gridinfoList.arr.length; index++) {
        const element = vm.$refs.monitorRef.girdItemRefList[index];
        // console.log('element:',element);
        let { top, right, bottom, left } = element;
        if (x > left && y > top && x < right && y < bottom) return index;
      }
    },
    //根据摄像头id获取播放流地址数据
    async deviceMediaLive (id,i) {
      this.$refs.monitorRef.loadData[i].isLoading = true
      let dataObj = {
        'channelId':id,
      }
      const res = await apiUrl.deviceMediaLive(dataObj)
      this.$refs.monitorRef.loadData[i].isLoading = false
      if(res?.code == 200){
        // return location.protocol == 'https:'? res.data?.webrtc : res.data?.webrtcHttp
        return res.data?.hls || res.data?.https.replace("https", "webrtc")
      }else{
        this.$refs.monitorRef.loadData[i].tips = res.message
        let timeOut = setTimeout(() => {
          this.$refs.monitorRef.loadData[i].tips = ''
          clearTimeout(timeOut)
        }, 3000);
        // vm.$message.error(res.message)
        return ''
      }
    },
  }
}

</script>
<style lang='scss'>
.rtPreview{
  width: 100%;
  display: flex;
  padding-right: 1px;
  box-sizing: border-box;
  .title_nameR{
    // background: linear-gradient(to bottom,#07346b,#071a3a);
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    background: #022853;
    box-shadow: 0 5px 27px 5px rgba(7,130,255,.25) inset;
    .name{
      color: #fff;
      // background-image: linear-gradient(to bottom, #fff, #1783c8);
      // color: transparent;
      // -webkit-background-clip: text;
    }
    .csSelect{
      .el-input__inner{
        background: none;
        color: #fff;
        border: none;
      }
    }
  }
  .leftCont{
    flex-shrink: 0;
    width: 250px;
    box-sizing: border-box;
    position: relative;
    background-color: #071a3a;
    box-shadow: 0 5px 27px 5px rgba(7,130,255,.55) inset;
    .deviceComp{
      .searchBD{
        color: #fff;
        .el-input__inner{
          background: none;
          border-color: #284b96;
          color: #fff;
        }
      }
      .list{
        border-bottom: 2px solid #162958;
        &::-webkit-scrollbar{
          width: 4px;
          height: 4px;
        }
        &::-webkit-scrollbar-track{
          background: #333;
          border-radius:2px;
        }
        &::-webkit-scrollbar-thumb{
          background: #284b96;
          border-radius:4px;
          &:hover{
            background: #666;
          }
        }
      }
      .el-tree{
        background: none;
        color: #fff;
        .isDisabled{
          color: #999;
        }
      }
      .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
        background:none;
        color:#409EFF;
      }
      .el-tree .el-tree-node.is-focusable>.el-tree-node__content{
        background:none;
      }
      .el-tree-node__content:hover, .el-upload-list__item:hover{
        background: none;
      }
    }
    .el-tabs{
      height: calc(100% - 210px);
    }
    .el-tree{
      color: #000;
      .isDisabled{
        color: #ddd;
      }
    }
  }
  .rightCont{
    flex-grow: 1;
    // overflow: hidden;
    border-radius: 6px;
    position: relative;
    z-index: 0;
    margin-top: 25px;
    .monitorComp{
      .list{
        background: #031e44;
        box-shadow: 0 2px 10px 3px rgba(7,130,255,.55) inset;
        .item{
          border: 1px solid #114071;
          &.active{
            // box-shadow: 0 0px 3px 3px rgba(#409EFF,.7);
            box-shadow: none;
            border: 1px solid #3683d1;
            z-index: 2;
          }
          .el-loading-mask{
            background-color: rgba(0,0,0,.5);
          }
        }
      }
    }
  }
  .el-tabs{
    height: calc(100% - 210px);
    .el-tabs__header{
      .el-icon{
        font-size:20px;
        padding: 4px 3px;
      }
      .el-tabs__item:nth-child(2){padding-left: 3px;}
      .el-tabs__item:last-child{padding-right: 3px;}
    }
  }
}
</style>
